import React from "react"
import { makeStyles, Typography, Card, CardMedia, CardContent } from "@material-ui/core"
import { logger } from '../config/pino';
import PlaceholderImage from '../images/gd1.jpg'
import { Skeleton } from "@material-ui/lab";

interface Attribute {
  trait_type: string,
  value: string | number,
  display_type?: string
}

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200,
    // height: 250,
    textAlign: 'center',
    margin: 0,
    borderRadius: '10px',
  },
  cardContent: {
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '1rem !important'
  },
  cardText: {
    // display: 'flex',
    textAlign: 'left',
    fontSize: '0.8rem',
    fontWeight: "lighter"
  },
  cardSubText: {
    // display: 'flex',
    textAlign: 'left',
    fontSize: '0.8rem',
    fontWeight: 'normal'
  },
  cardTitle: {
    // display: 'flex',
    textAlign: 'left',
    fontSize: '1rem',
    font: 'normal normal medium 22px/27px Montserrat'
  },
  media: {
    margin: 0,
    objectFit: "contain"
  },
  loading: {
    height: '5rem',
    width: '100%',
  },
}));

// Takes an ERC721 Metadata object and renders a small card with it.
export function NFTMiniCard({metadata}) {
  // console.log(`Rendering mini card with data: ${JSON.stringify(metadata)}`)
  // Handle null render when given an empty object
  let isLoading;
  metadata && Object.keys(metadata).length === 0 && metadata.constructor === Object ? isLoading = true : isLoading = false

  let year;
  let game;
  const nftImage = !!metadata.image ? metadata.image : PlaceholderImage
  const nftName = !!metadata.name ? metadata.name : (<Skeleton></Skeleton>)
  
  // Parse the Attributes Array in the Metadata Object and pull out the attributes we care about
  if(!!metadata.attributes && metadata.attributes.length > 0){
    console.log(`Attributes supplied to NFTMiniCard.tsx: `)
    console.log(metadata.attributes)
    const yearAttribute = metadata.attributes.find((item: Attribute)=>
      item.trait_type === 'Year'
    )
    year = !!yearAttribute ? yearAttribute.value : null
    const gameAttribute = metadata.attributes.find((item: Attribute)=>
      item.trait_type === 'Game'
    )
    game = !!gameAttribute ? gameAttribute.value : null

    console.log(`Game: ${game}, year: ${year}`)
  }
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.card}>

      {isLoading ? <Skeleton variant="rect" className={classes.loading} /> :
        <CardMedia
          className={classes.media}
          component="img"
          // xyxy
          image={nftImage}
          title="NFT Image"
        />
      }

      {!isLoading &&
        <CardContent className={classes.cardContent}>

          {/* <Avatar className={classes.avatar}>
            {!isError && (<NftIcon />)}
            {!!isError && (<ErrorOutlineIcon />)}
          </Avatar> */}

          <Typography className={classes.cardTitle} gutterBottom>
            {nftName}
          </Typography>
          {!!game &&
            <Typography className={classes.cardText} gutterBottom>
            {game}
            </Typography>
          }
          {!!year &&
            <Typography className={classes.cardSubText} >
            {year}
            </Typography>
          }
        </CardContent>
      }
    </Card>
  )
}
