
import React from "react"
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { Grid } from "@material-ui/core";
import guinnessLogo from '../images/guinness.svg'
import awsLogo from '../images/aws.svg'
import tissotLogo from '../images/tissot.svg'
import doveLogo from '../images/DoveMen.jpg'

const useStyles = makeStyles((theme) =>
  createStyles({

    root: {
      padding: "2rem 0.3rem 1rem 0.3rem",
      backgroundColor: "#747474",
      margin: "0",
      width: "100%",
      maxWidth: "100%",
    },
    gridContainerLogos: {
      color: "black",
      width: "100%",
      paddingTop: "1rem",
      margin: '0',
      justifyContent: 'space-around',
      alignItems: 'center'
    },

    whiteTitleText: {
      color: "white",
      textAlign: "center",
      font: "normal normal normal 36px/44px Montserrat",
      letterSpacing: "0px",
    },

    whiteSubHeadingText: {
      color: "white",
      textAlign: "center",
      font: "normal normal 300 18px/24px Montserrat",
      letterSpacing: "0px",
    },

  }),
);


export default function Sponsors() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.whiteTitleText}>Our Partners</h1>
      <h3 className={classes.whiteSubHeadingText}>Rugby Moments is sponsored by:</h3>
      <Grid
        container
        spacing={8}
        className={classes.gridContainerLogos}
        justify="center"
      >
        {/*Guinness Icon*/}
        <Grid item>
          <img src={guinnessLogo}/>
        </Grid>
        {/*Tissot Icon*/}
        <Grid item>
        <img src={tissotLogo}/>
        </Grid>
        {/*AWS Icon*/}
        <Grid item>
        <img src={awsLogo}/>
        </Grid>
        {/*Dove Icon*/}
        <Grid item>
        <img src={doveLogo}/>
        </Grid>
        
      </Grid>
    </div>
  )
}


