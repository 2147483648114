import React from "react"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography, Grid } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { NFTMiniCard } from "../components/NFTMiniCard"
import { graphql, Link } from "gatsby"
import { EmailSubmission } from "../components/EmailSubmission"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Sponsors from "../components/sponsors"

// Page styles
const useStyles = makeStyles((theme) =>
  createStyles({
    arrowIcon: {
      paddingTop: '0.4rem',
      margin: 'auto 0'
    },
    textContainer: {
      margin: "0 auto",
      padding: "1rem",
      //backgroundColor: 'white'
      // using the padding for now to put the text near the middle
      // backgroundColor: '#aaffff'
    },
    maxWidthOpenSeaContainer: {
      alignItems: "center",
      background: "#2E2E2E",
      "border-bottom": "none",
      margin: "0 auto",
      marginBottom: "0",
      paddingBottom: "0",
      padding: "2%",
      width: "100%",
      "max-width": "100%",
    },
    openSeaImage: {
      display: "block",
      margin: "1rem auto 0 auto",
      paddingBottom: "1rem",
      width: "50%",
      objectFit: "contain",
    },
    maxWidthScrollingContainer: {
      //backgroundColor: "white",
      paddingTop: "1rem",
      paddingBottom: "0rem",
      margin: 0,
      "max-width": "100%",
      width: "100%",
      "overflow-x": "scroll",
    },
    maxWidthSubmitEmailContainer: {
      alignItems: "center",
      background: "white",
      "border-bottom": "none",
      margin: "0 auto",
      marginBottom: "0",
      paddingBottom: "0",
      padding: "2%",
      width: "100%",
      "max-width": "100%",
    },
    gridContainer: {
      width: "max-content",
      margin: "0 auto",
    },
    nftLink: {
      textDecoration: "none",
    },
    blackTitleText: {
      color: "black",
      textAlign: "center",
      font: "normal normal normal 36px/44px Montserrat",
      letterSpacing: "0px",
    },
    blackSubHeadingText: {
      color: "black",
      textAlign: "left",
      font: "normal normal 300 18px/24px Montserrat",
      letterSpacing: "0px",
      [theme.breakpoints.up('md')]: {
        font: "normal normal 300 24px/28px Montserrat",
      },
    },
    blackLinkText: {
      color: "black",
      textAlign: "center",
      font: "normal normal medium 18px/24px Montserrat",
      letterSpacing: "0px",
      textDecorationColor: "black"
    },
  }))

export default function IndexPage({ data }) {
  const classes = useStyles()
  console.log(data)

  return (
    <Layout>
      <SEO title="Rugby Moments" />

      {/* 1: scrollable NFTMiniCards cards */}
      <Container className={classes.textContainer}>
        <Typography
          variant="subtitle1"
          align={"center"}
          gutterBottom
          className={classes.blackTitleText}
        >
          Your Piece of History
        </Typography>
        <Typography
          variant="subtitle1"
          align={"center"}
          gutterBottom
          className={classes.blackSubHeadingText}
        >
          2,000 Genesis Edition Moments, 126 Featured Players, 6 Nations.
        </Typography>
      </Container>
      <Container className={classes.maxWidthScrollingContainer}>
        <Grid
          container
          className={classes.gridContainer}
          spacing={5}
          justify="center"
        >
          {data.nft.nfts.map(object => {
            return (
              <Grid item key={object.token_id}>
                <Link
                  to={`/nft/${object.address}/${object.token_id}/`}
                  className={classes.nftLink}
                >
                  <NFTMiniCard
                    // @ts-ignore
                    metadata={object}
                  ></NFTMiniCard>
                </Link>
              </Grid>
            )
          })}
        </Grid>
      </Container>
      <Container className={classes.textContainer}>
        <Link to="/nfts">
          <Typography
            gutterBottom
            className={classes.blackLinkText}>
            <ArrowForwardIcon fontSize={"small"} className={classes.arrowIcon} /> See more of your favourite Rugby Moments
          </Typography>
        </Link>
      </Container>

      {/* 3: Grid of partner logos */}
      <Sponsors />
      {/* 4: Submit email section */}
      <EmailSubmission></EmailSubmission>
      {/* 5: OpenSea section */}
      <Container className={classes.maxWidthOpenSeaContainer}>
        <Typography
          component="h1"
          variant="h5"
          align={"center"}
          gutterBottom
          style={{ color: "white", fontSize: "1rem" }}
        >
          Auctions for new Rugby Moments every week on
        </Typography>
        {/* wrap the image in a href to OpenSea */}
        <a href="https://opensea.io/" target="_blank" rel="noopener noreferrer">
          <img
            className={classes.openSeaImage}
            src={"/opensea-logo-flat-colored-white.png"}
            alt="Opensea Logo"
          />
        </a>
      </Container>

    </Layout>
  )
}

export const query = graphql`
  query NFTQuery {
    nft {
      nfts {
        external_url
        image
        name
        title
        address
        token_id
        animation_url
        attributes { 
          trait_type
          display_type
          value
         }
      }
    }
  }
`
